<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-form class="d-flex" @submit.prevent="fetch(1)">
              <b-form-input class="mr-1" v-model="filter.q" placeholder="Recherche par Marque, Modèle, Immat, Client..." />
              <b-button type="submit"  variant="primary">Recherche</b-button>
              <b-button  v-if="$can('exporter-client', 'client')" @click="importHandler"  class="ml-1" variant="primary">Exporter</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2 d-flex justify-content-between">

        <b-button
            v-if="$can('creer-client', 'client')"
            variant="primary"
            :to="{ name: 'creer-client'}"
        >
          Créer client
        </b-button>
        <div class="align-self-center">
          {{pagination.total}} clients
        </div>
      </div>
      <b-table v-if="items"
               ref="selectableTable"
               :fields="fields"
               :items="items"

               responsive
               class="mb-0"

      >
        <template #cell(actions)="data">

          <b-button :to="{ name: 'editer-client', params : {id : data.item.id }}" v-ripple.400="'rgba(113, 102, 240, 0.15)'"  size="sm" variant="outline-primary"  >
            Editer
          </b-button>
        </template>
      </b-table>



    </b-card>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="pagination.current_page"
              :total-rows="pagination.total"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="fetch"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>

</template>

<script>
 
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from "@/libs/axios";


export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: ['id',  'nom','prenom', 'email', 'telephone', 'cin', {key : 'actions'  }],      /* eslint-disable global-require */
      items: [],
      pagination : {},
      filter : {
        q : ''
      }

    }
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page){
      let _this = this;
      this.$store.dispatch('clientsModule/fetchClients', {
        page: page,
        q : _this.filter.q
      }).then(function(response){
        _this.items = response.data;
        _this.pagination = response.meta
      })
    },
    importHandler(){
      let _this = this;
      axios.get(
          process.env.VUE_APP_API_URL + 'api/clients?export=1&q=' + _this.filter.q,
          {responseType: 'blob'} // !!!
      ).then((response) => {
        window.open(URL.createObjectURL(response.data));
      })
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
